<template>
  <HyperCodeHeroProductSection
    :id="item.fields.id"
    :title="headline"
    :second-title="secondTitle"
    :text="text"
    :buttons="buttons"
    :media="media"
    :text-side="textSide"
    :color-mode="lightDarkColorMode"
  >
    <template #rteText>
      <LibRichTextRenderer v-if="descriptionRte" :rich-text="descriptionRte" />
    </template>
  </HyperCodeHeroProductSection>
</template>

<script setup lang="ts">
import { HeroProductSection as HyperCodeHeroProductSection } from '@hypercodestudio/basler-components';
import type {
  TextSide,
  ColorMode
} from '@hypercodestudio/basler-components/dist/components/content-modules/sections/HeroProductSection.vue';
import { getLinkIconPosition } from '~/utils/getLinkIconPosition';
import type { IHeroProductSection } from '~/lib/ContentfulService';
import { useMedia } from '~/composables/useMedia';
import { isEntryResolveError } from '~/utils/guards/isEntryResolveError';
import { isDefined } from '~/utils/guards/isDefined';
import { mapHeroSubtitle } from '~/utils/mapHeroSubtitle';
import { mapHeroHeadline } from '~/utils/mapHeroHeadline';
import type { ColorMode as ThemeColorMode } from '@hypercodestudio/basler-components/dist/models/ColorMode';
import LibRichTextRenderer from '~/components/lib/RichTextRenderer.vue';

interface Props {
  item: IHeroProductSection;
  colorMode: ColorMode | ThemeColorMode;
}
const props = defineProps<Props>();

const headline = computed(() => mapHeroHeadline(props.item));
const secondTitle = computed(() => mapHeroSubtitle(props.item));

// Der Link Type beinhaltet aktuell nur einen Text. Hypercode wurde in Ticket #1260 darüber informiert.
const buttons = computed(() =>
  props.item.fields.links
    ?.filter(isDefined)
    ?.filter((entry) => !isEntryResolveError(entry))
    ?.map((field) => ({
      text: field.fields.linkText,
      link: useBuildLinkInterface(field),
      size: field.fields.linkStyleSize,
      styleType: field.fields.linkStyle ?? 'default',
      iconName: field.fields.linkIcon,
      targetBlank: field.fields.linkTarget === '_blank',
      iconType: getLinkIconPosition(field.fields.linkIconPosition)
    }))
);

const text = computed(() => {
  if (props.item.fields.text) {
    return {
      lead: false,
      content: props.item.fields.text
    };
  }
});

const descriptionRte = computed(() => props.item.fields.descriptionRte ?? '');

const lightDarkColorMode = computed<ColorMode>(() => {
  if (props.colorMode === 'dark-purple') {
    return 'dark';
  }

  return props.colorMode;
});

const textSide = computed<TextSide>(
  () => props.item.fields.textAlignment ?? 'left'
);

const media = await useMedia(props.item.fields.media, {
  keepAspectRatio: true
});
</script>
